<template>
  <a-col span="24">
    <a-card :title="title" size="small" hoverable>
      <a-input
        name="{{title}}"
        :value="taskValue"
        placeholder="0.00"
        type="text"
        step="any"
        :id="id"
        @input="onChange"
      />
      <!-- oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" -->
    </a-card>
  </a-col>
</template>
<script>
export default {
  name: 'number-type-task-param-component',
  props: ['title', 'param', 'taskParam', 'updatedTaskJson', 'maxLength', 'id'],
  mounted() {
    this.taskValue = this.updatedTaskJson[this.param][this.taskParam];
  },
  watch: {
    taskValue(val) {
      this.updatedTaskJson[this.param][this.taskParam] = Number(val);
    },
  },
  data() {
    return {
      taskValue: null,
    };
  },
  methods: {
    onChange(e) {
      const { value } = e.target;
      if (Number.isNaN(Number(value))) return;
      const maxValue = Math.pow(10, this.maxLength) - 1;
      if (Number(value) <= maxValue) this.taskValue = value;
    },
  },
};
</script>
