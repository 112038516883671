<template>
  <!-- Edit Task Json Modal -->
  <div class="element-wrapper mt-5 h-100">
    <edit-task-json-modal
      :showEditTaskJsonModal="showEditTaskJsonModal"
      @close="showEditTaskJsonModal = false"
      :taskJson="taskJson"
      :taskId="taskId"
      :taskName="taskName"
      :processes="processes"
      @updateTaskJsonEmit="(taskJson) => $emit('updateTaskJsonEmit', taskJson)"
    ></edit-task-json-modal>
    <div class="element-box my-custom-scrollbar h-100">
      <div class="os-tabs-w">
        <div class="row">
          <div class="col-12 mt-3">
            <!-- <div class="d-flex">
              <button
                id="v-edit-task-json-button"
                :disabled="!taskId"
                class="btn btn-primary ml-auto"
                @click="openEditTaskJsonModal"
              >
                Edit task Parameters
              </button>
            </div> -->
            <div class="task-container" v-if="taskName" :key="processes">
              <div class="row task py-2 mb-2" v-if="taskName">
                <div class="col-8">
                  <h5>
                    <strong>Name: </strong>
                    <span class="text-secondary">{{ taskName }}</span>
                  </h5>
                </div>
              </div>

              <!-- Steps List -->
              <div
                class="card p-2"
                v-for="(process, processIndex) in processes"
                :key="processIndex"
              >
                <h6>
                  <strong>Process: </strong>
                  <span class="text-secondary">{{ process.name }}</span>
                </h6>
                <ul class="list-group list-group-flush" v-if="taskName">
                  <li
                    class="list-group-item"
                    v-for="(step, stepIndex) in process.steps"
                    :key="stepIndex"
                  >
                    <b>Step:</b> {{ step ? step.name : '' }}

                    <!-- Substeps List -->
                    <ul class="list-group">
                      <li
                        class="list-group-item substep-items d-flex align-items-center"
                        style="border: 0 none; padding: 2px 16px; font-size: 14px"
                        v-for="(substep, substepIndex) in step.substeps"
                        :key="substep + substepIndex"
                      >
                        <b class="mr-1">substep: </b>
                        <small>{{ substep }}</small>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import getTaskDetails from '../../../shared/Helpers/getTaskDetails';
import EditTaskJsonModal from './EditTaskJson/EditTaskJsonModal.vue';

export default {
  components: {
    EditTaskJsonModal,
  },
  props: ['taskDetails', 'taskId', 'taskJson'],
  data: function() {
    return {
      processes: [],
      taskName: '',
      showEditTaskJsonModal: false,
    };
  },
  emits: ['updateTaskJsonEmit'],
  mounted() {
    console.log('bop mounted');
    getTaskDetails.apply(this, [this.taskDetails]);
  },
  watch: {
    taskDetails: function(value) {
      console.log('bop task watch', value);
      getTaskDetails.apply(this, [value]);
    },
  },
  methods: {
    openEditTaskJsonModal() {
      this.showEditTaskJsonModal = true;
    },
  },
};
</script>
<style></style>
