<template>
  <a-row :gutter="16">
    <a-col :span="12" class="d-flex flex-column">
      <a-card>
        <video
          id="myVideo"
          ref="videoRecorder"
          playsinline
          class="video-js vjs-default-skin"
          style="height:55vh !important;"
        />
      </a-card>
      <a-button
        type="primary"
        class="mx-auto mt-3"
        @click.prevent="submitVideo()"
        :loading="isUploading"
        :disabled="isSaveDisabled"
        >{{ isUploading ? 'Uploading' : 'Upload' }}</a-button
      >
      <!-- <br /> -->
      <a-progress class="mt-2" :percent="progress" v-if="isUploading" />
    </a-col>
    <a-col :span="showDisclaimer ? 8 : 12">
      <a-card
        size="small"
        :title="taskName"
        class="w-100"
        :bodyStyle="{
          height: '65vh',
          overflowY: 'auto',
        }"
      >
        <a-tree
          class="draggable-tree"
          :expandedKeys="expandedKeys"
          defaultExpandAll
          :selectable="false"
          :tree-data="treeData"
        />
      </a-card>
    </a-col>
    <a-col :span="4" v-if="showDisclaimer">
      <a-alert
        message="Note"
        description="Due to possible connection issues, please use your camera app to
            record videos longer than 20 minutes and upload it using form."
        type="info"
        show-icon
        closable
        @close="showDisclaimer = false"
      />
    </a-col>
  </a-row>
</template>

<script>
import 'video.js/dist/video-js.css';
import 'videojs-record/dist/css/videojs.record.css';
import videojs from 'video.js';
import 'webrtc-adapter';
import RecordRTC from 'recordrtc';
import { useToast } from 'vue-toastification';
import Record from 'videojs-record/dist/videojs.record.js';
import FFmpegjsEngine from 'videojs-record/dist/plugins/videojs.record.ffmpegjs.js';
import TsEBMLEngine from 'videojs-record/dist/plugins/videojs.record.ts-ebml.js';
import httpClient from 'src/service/httpClient';
import MainProcess from '../../import/BillOfProcess.vue';
import getTaskDetails from '../../../../shared/Helpers/getTaskDetails';
import recordOptions from 'src/config/video-recorder-config.js';

export default {
  props: ['task_id', 'taskDetails'],
  emits: ['uploadFile', 'closeModal'],
  components: { MainProcess },
  setup() {
    const toast = useToast();
    return { toast };
  },

  data() {
    return {
      //=========== Task Details
      processes: [],
      taskName: '',
      treeData: [],
      expandedKeys: [],
      //===========
      showDisclaimer: true,
      player: '',
      progress: 0,
      isSaveDisabled: true,
      isUploading: false,
    };
  },

  mounted() {
    this.setupRecorder();
    getTaskDetails.apply(this, [this.taskDetails]);
  },

  beforeUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  },

  watch: {
    taskDetails: function(value) {
      getTaskDetails.apply(this, [value]);
    },
    processes(value) {
      const tempExpand = [];
      this.treeData = value.map((p) => {
        tempExpand.push(p.name);
        return {
          title: 'Process: ' + p.name,
          key: p.name,
          children: p.steps.map((s) => {
            tempExpand.push(s.name);
            return {
              title: 'Step: ' + s.name,
              key: s.name,
              children: s.substeps.map((ss) => ({
                title: 'Substep: ' + ss,
                key: ss,
              })),
            };
          }),
        };
      });
      this.expandedKeys = tempExpand;
    },
  },

  methods: {
    setupRecorder() {
      try {
        this.player = videojs(this.$refs.videoRecorder, recordOptions);
      } catch (error) {
        console.log('error in recorder: ', error);
      }

      if (!this.player) return;
      const events = this.getRecorderEvents();

      Object.entries(events).forEach(([event, cb]) => {
        this.player.on(event, cb);
      });
    },

    getRecorderEvents() {
      return {
        deviceReady: () => {
          this.showDisclaimer = true;
        },
        deviceError: () => {
          console.log('device error:', this.player.deviceErrorCode);
        },
        error: (_, error) => {
          console.error('error:', error);
        },
        finishRecord: () => {
          this.isSaveDisabled = false;
        },
      };
    },

    submitVideo() {
      this.isSaveDisabled = true;

      this.player.convertedData.name = this.getVideoName();
      this.player.convertedData.name += '.mp4';

      const data = this.createFormData();
      this.upload(data);
    },

    createFormData() {
      var formData = new FormData();
      formData.append(
        'video',
        this.player.convertedData,
        this.player.convertedData.name
      );
      formData.append('task_id', this.task_id);
      formData.append('Validation', 'False');
      return formData;
    },

    async upload(data) {
      this.isUploading = true;
      const res = await httpClient.upload(
        'organization/task/upload/',
        data,
        this
      );

      this.isUploading = false;
      this.progress = 0;
      if (res === 'error') return;

      this.$emit('uploadFile');
      this.$emit('closeModal');
    },

    pad2(n) {
      return (n < 10 ? '0' : '') + n;
    },

    getDateInfo() {
      const date = new Date();
      const month = this.pad2(date.getMonth() + 1);
      const day = this.pad2(date.getDate());
      const year = date.getFullYear();
      const hour = date.getHours();
      const minute = date.getMinutes();
      let second = date.getSeconds();

      if (second.toString().length < 2) second = '0' + second;

      return { year, month, day, hour, minute, second };
    },

    getVideoName() {
      const { year, month, day, hour, minute, second } = this.getDateInfo();
      return `${year}-${month}-${day}${hour}-${minute}-${second}`;
    },
  },
};
</script>
<style scoped>
.disclaimer-container {
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray;
}

.disclaimer-message {
  color: red;
  font-size: 15px;
  margin: 0 1em;
}

.cross-icon {
  cursor: pointer;
}

.video-js {
  width: 100% !important;
  height: 100% !important;
}

.progress {
  border: 1px solid lightgray;
}

.element-wrapper {
  padding: 0 0.5em !important;
  margin: 0 !important;
}
</style>
