<template>
  <a-modal
    centered
    v-model:visible="showVideoModal"
    :title="videoToPreview?.name || 'Title'"
    :footer="null"
    id="rd-preview-video-modal"
  >
    <template #closeIcon>
      <close-outlined id="rd-preview-modal-close-btn" />
    </template>
    <video
      class="w-100"
      controls
      :src="videoToPreview?.fileURL"
      id="rd-preview-video"
    ></video>
  </a-modal>
  <a-row :gutter="[16, 16]">
    <a-col :xs="24" :sm="24" :md="8" :lg="8">
      <a-select
        :options="tasks"
        class="w-100"
        show-search
        ref="upload_task_select"
        v-model:value="selectedTask"
        @change="handleTaskChange"
        placeholder="Select Task"
        :filter-option="filterOption"
        id="rd-upload-video-select-task"
      ></a-select>
    </a-col>
    <a-col span="24">
      <!-- <a-tabs v-model:activeKey="activeTab" size="small">
        <a-tab-pane key="1">
          <template #tab>
            <span>
              <upload-outlined />
              Upload
            </span>
          </template> -->
      <a-row>
        <a-col
          :xs="24"
          :sm="12"
          :md="12"
          :lg="12"
          class="mx-auto"
          style="height: 30vh !important"
        >
          <a-upload-dragger
            :fileList="[]"
            name="file"
            multiple
            :beforeUpload="(f) => false"
            @change="handleAddFile"
            :class="{
              'disable-click': isUploading,
            }"
            accept=".mkv,.mp4"
            id="rd-upload-video-dragger"
          >
            <p class="ant-upload-drag-icon">
              <inbox-outlined></inbox-outlined>
            </p>
            <p class="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </a-upload-dragger>
        </a-col>
      </a-row>
      <a-row>
        <a-col
          :xs="24"
          :sm="12"
          :md="12"
          :lg="12"
          class="d-flex flex-column mx-auto"
        >
          <div class="list-container">
            <a-list
              item-layout="horizontal"
              :data-source="fileList"
              size="small"
              rowKey="uid"
              id="rd-upload-video-filelist"
            >
              <template #renderItem="{ item, index }">
                <a-list-item>
                  <a-list-item-meta
                    :title="item.name"
                    :description="getFileSize(item.size)"
                    class="d-flex align-items-center"
                    :id="'rd-upload-file-' + item.name"
                  >
                    <template #avatar class="d-flex">
                      <video :src="item.fileURL" width="50" class="mt-1" />
                    </template>
                  </a-list-item-meta>
                  <template #actions>
                    <a-tooltip title="Preview file">
                      <eye-outlined
                        class="text-primary clickable"
                        :class="{
                          'disable-click': isDisabled,
                        }"
                        @click="handlePreview(item)"
                        :id="'upload-video-preview-' + index"
                      />
                    </a-tooltip>
                    <a-tooltip title="Remove file">
                      <delete-outlined
                        :class="{
                          'disable-click': isDisabled,
                        }"
                        class="text-danger clickable"
                        @click="handleRemove(item)"
                        :id="'upload-video-delete-' + index"
                      />
                    </a-tooltip>
                  </template>
                </a-list-item>
              </template>
            </a-list>
          </div>
          <br />
          <a-button
            type="primary"
            class="mx-auto"
            :loading="isUploading"
            :disabled="isDisabled"
            @click="uploadVideos"
            id="upload-video-submit-btn"
            >{{ isUploading ? 'Uploading' : 'Upload' }}</a-button
          >
          <br />
          <!-- <wifi-signals :currentNetworkType="networkType"></wifi-signals> -->
          <div v-if="isUploading" class="m-auto text-center">
            <div id="file-uploading-status">
              {{ uploadingFileName }} uploading...
            </div>
            <div>{{ progressText }}</div>
          </div>
          <a-progress
            id="file-upload-progress"
            :percent="progress"
            v-if="isUploading"
          />
        </a-col>
      </a-row>
      <!-- </a-tab-pane> -->
      <!-- <a-tab-pane key="2">
          <template #tab>
            <span>
              <video-camera-add-outlined />
              Record
            </span>
          </template>
          
          <video-recorder
            v-if="activeTab === '2'"
            @uploadFile="$emit('uploadFile', selectedTask)"
            @closeModal="handleCloseModal"
            :task_id="selectedTask"
            :taskDetails="taskDetails"
          >
          </video-recorder>
        </a-tab-pane> -->
      <!-- </a-tabs> -->
    </a-col>
  </a-row>
</template>

<script>
import httpClient from 'src/service/httpClient.js';
import VideoRecorder from './RecorderV2.vue';
import { CancelToken } from 'axios';
import {
  InboxOutlined,
  DeleteOutlined,
  EyeOutlined,
  VideoCameraAddOutlined,
  UploadOutlined,
  CloseOutlined,
} from '@ant-design/icons-vue';
import spaceMixin from 'src/mixins/handleSpace';
import WifiSignals from './WifiSignals.vue';
import { message } from 'ant-design-vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  inject: ['toast'],
  mixins: [spaceMixin],
  props: ['tasks'],
  emits: ['closeModal'],
  components: {
    VideoRecorder,
    InboxOutlined,
    DeleteOutlined,
    EyeOutlined,
    VideoCameraAddOutlined,
    UploadOutlined,
    CloseOutlined,
    WifiSignals,
  },

  data() {
    return {
      activeTab: '1',
      fileList: [],
      progress: 0,
      isUploading: false,
      cancelToken: null,
      cancel: null,
      videoToPreview: null,
      showVideoModal: false,
      progressText: '',
      networkType: '',
      isSlowSpeed: false,
      uploadingFileName: null,
      filesUploadSuccess: false,
    };
  },

  watch: {
    // selectedTask(value) {
    //   this.fetchTaskDetails(value);
    // },
    isSlowSpeed(val) {
      if (val) {
        // message.info('You have a slow connection', 6);
      }
    },
  },

  computed: {
    ...mapGetters(['selectedTask', 'taskDetails']),
    isDisabled() {
      return this.fileList.length === 0 || this.isUploading;
    },
  },

  created() {
    const self = this;
    this.cancelToken = new CancelToken((cb) => {
      self.cancel = cb;
    });

    window.addEventListener('offline', this.handleNetworkFailure);
  },

  methods: {
    ...mapActions(['setSelectedTask', 'fetchTaskDetails']),

    handleTaskChange(taskId) {
      this.setSelectedTask(taskId);
      this.$refs.upload_task_select.blur();
    },

    handleCloseModal() {
      this.$emit('closeModal');
      this.activeTab = 1; // reseting tab to Upload
    },

    updateVideoList() {
      this.$emit('uploadFile', this.selectedTask);
    },

    handleNetworkFailure() {
      console.log('offline handler');
      this.cancel('Poor internet connection, Please reload and try again!');
    },

    getFileSize(sizeInBytes) {
      return (sizeInBytes / 1024 / 1024).toFixed(2) + ' MB';
    },

    isFileExist(file) {
      return this.fileList
        .map((e) => e.name + e.size)
        .includes(file.name + file.size);
    },

    handleAddFile({ file }) {
      if (this.isFileExist(file)) {
        this.toast.info(`${file.name} already exists!`, { timeout: 2000 });
        return;
      }
      if (file.name.includes('.mp4') || file.name.includes('.mkv')) {
        file['fileURL'] = URL.createObjectURL(file);
        this.fileList = [...this.fileList, file];
      }
    },

    handlePreview(file) {
      this.videoToPreview = file;
      this.showVideoModal = true;
    },

    handleRemove(file) {
      this.fileList = this.fileList.filter((f) => f.uid !== file.uid);
    },

    async uploadVideos() {
      if (!this.selectedTask) {
        this.toast.warning('Please first select the task!!');
        return;
      }
      for (const file in this.fileList) {
        const name = `file${file + 1}`;
        let formData = this.createFormData(this.fileList[file], name);
        await this.upload(this.fileList[file].name, formData);
      }
      this.filesUploadSuccess && this.handleResetAfterUpload();
    },

    createFormData(file, name) {
      let formData = new FormData();
      formData.append('task_id', this.selectedTask);
      formData.append('Validation', 'False');
      formData.append(name, file);
      return formData;
    },

    async upload(fileName, data) {
      this.isUploading = true;
      this.uploadingFileName = fileName;
      this.filesUploadSuccess = false;

      const res = await httpClient.upload(
        'organization/task/upload/',
        data,
        this,
        false,
        true,
        true,
        'Video(s) uploaded successfully!',
        'Error occured while uploading video!'
      );
      this.progress = 0;
      this.isUploading = false;
      if (res === 'error') return;
      this.filesUploadSuccess = true;
    },

    handleResetAfterUpload() {
      this.fileList = [];
      this.$emit('closeModal');
    },
  },
};
</script>

<style scoped>
.files-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  padding: 1em;
}

.list-container {
  max-height: 32vh;
  overflow-y: auto;
}
</style>
