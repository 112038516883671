<template>
  <a-row :gutter="[0, 16]">
    <a-col span="24">
      <a-card
        :loading="isUpdatingTaskParams"
        hoverable
        size="small"
        title="Telemetry Parameters"
        :bodyStyle="{ height: '31vh', overflowY: 'auto' }"
      >
        <a-row :gutter="[0, 8]">
          <!-- <multiselect-type-task-param-component
            v-for="param in telemetryParams"
            :key="param.title"
            :title="param.title"
            :param="param.param"
            :task-param="param.taskParam"
            :updated-task-json="param.updatedTaskJson"
            :processes="param.taskProcesses"
            :options="param.stepsNumbersWithinEachProcessForStart"
            :selectedOptions="param.selected_required_steps_for_start"
            :is-process-based="param.isProcessBased"
            @updateTaskParam="param.updateTaskParam"
          /> -->

          <!--required_steps_for_start-->
          <multiselect-type-task-param-component
            :title="'Required Steps for Cycle Start'"
            :param="'cycle_calculation_params'"
            :task-param="'required_steps_for_start'"
            :updated-task-json="updatedTaskJson"
            :processes="taskProcesses"
            :options="stepsNumbersWithinEachProcessForStart"
            :selectedOptions="selected_required_steps_for_start"
            :is-process-based="true"
            id="required-step-for-cycle-start-input"
            @updateTaskParam="updateTaskParam"
          />

          <!--required_steps_for_end-->
          <multiselect-type-task-param-component
            :title="'Required Steps for Cycle End'"
            :param="'cycle_calculation_params'"
            :task-param="'required_steps_for_end'"
            :updated-task-json="updatedTaskJson"
            :processes="taskProcesses"
            :options="stepsNumbersWithinEachProcessForEnd"
            :selectedOptions="selected_required_steps_for_end"
            :is-process-based="true"
            id="required-step-for-cycle-end-input"
            @updateTaskParam="updateTaskParam"
          />

          <!--min_percentage_of_steps_required_to_end_cycle-->
          <number-type-task-param-component
            :title="'Minimum Percentage Steps required for Cycle End (%)'"
            :param="'cycle_calculation_params'"
            :task-param="'min_percentage_of_steps_required_to_end_cycle'"
            :updated-task-json="updatedTaskJson"
            :max-length="5"
            id="min-steps-required-for-cycle-end-input"
          />

          <!--minimum_cycle_time-->
          <number-type-task-param-component
            :title="'Minimum Cycle Time'"
            :param="'cycle_calculation_params'"
            :task-param="'minimum_cycle_time'"
            :updated-task-json="updatedTaskJson"
            :max-length="5"
            id="minimum-cycle-time"
          />

          <!--maximum_cycle_time-->
          <number-type-task-param-component
            :title="'Maximum Cycle Time'"
            :param="'cycle_calculation_params'"
            :task-param="'maximum_cycle_time'"
            :updated-task-json="updatedTaskJson"
            :max-length="5"
            id="maximum-cycle-time"
          />

          <sub-step-times-json :processes="taskProcesses" />
        </a-row>
      </a-card>
    </a-col>
    <a-col span="24">
      <a-card
        hoverable
        :loading="isUpdatingTaskParams"
        size="small"
        title="Training Parameters"
        :bodyStyle="{ height: '31vh', overflowY: 'auto' }"
      >
        <a-row :gutter="[0, 8]">
          <!--augmentations-->
          <multiselect-type-task-param-component
            :title="'Augmentations'"
            :param="'training_params'"
            :task-param="'augmentations'"
            :updated-task-json="updatedTaskJson"
            :processes="taskProcesses"
            :is-process-based="false"
            :options="augmentations"
            :selected-options="selectedAugmentations"
            :is-single-select="false"
            @updateTaskParam="updateTaskParam"
            id="augmentation-param-input"
          />
          <!--model_choice-->
          <multiselect-type-task-param-component
            :title="'Model Choice'"
            :param="'training_params'"
            :task-param="'model_choice'"
            :updated-task-json="updatedTaskJson"
            :is-process-based="false"
            :options="modelTypes"
            :selected-options="selectedModels"
            :is-single-select="true"
            @updateTaskParam="updateTaskParam"
            id="model-choice-input"
          />
          <!--threshold-->
          <number-type-task-param-component
            :title="'Background Frame Threshold'"
            :param="'training_params'"
            :task-param="'threshold'"
            :updated-task-json="updatedTaskJson"
            :max-length="2"
            id="bg-frame-threshold-input"
          />
          <!--spatial_model_iterations-->
          <number-type-task-param-component
            :title="'Spatial Model Iteration'"
            :param="'training_params'"
            :task-param="'spatial_model_iterations'"
            :updated-task-json="updatedTaskJson"
            :max-length="5"
            id="spatial-model-itr-input"
          />
          <!--spatial_model_batch_size-->
          <number-type-task-param-component
            :title="'Spatial Model Batch Size'"
            :param="'training_params'"
            :task-param="'spatial_model_batch_size'"
            :updated-task-json="updatedTaskJson"
            :max-length="2"
            id="spatial-model-size-input"
          />
          <!--contextual_model_iterations-->
          <number-type-task-param-component
            :title="'Contextual Model Iteration'"
            :param="'training_params'"
            :task-param="'contextual_model_iterations'"
            :updated-task-json="updatedTaskJson"
            :max-length="2"
            id="context-model-itr-input"
          />
          <!--contextual_model_batch_size-->
          <number-type-task-param-component
            :title="'Contextual Model Batch Size'"
            :param="'training_params'"
            :task-param="'contextual_model_batch_size'"
            :updated-task-json="updatedTaskJson"
            :max-length="2"
            id="context-model-size-input"
          />
        </a-row>
      </a-card>
    </a-col>
  </a-row>
  <div class="d-flex pt-3">
    <a-space class="ml-auto">
      <a-button
        @click="
          showModal({
            modalType: types.SET_SHOW_TASK_PARAMS_MODAL,
            value: false,
          })
        "
        :disabled="isUpdatingTaskParams"
        id="task-param-cancel-button"
      >
        Cancel
      </a-button>
      <a-button
        type="primary"
        @click="updateTaskJsonParams"
        :loading="isUpdatingTaskParams"
        id="task-param-save-button"
      >
        Update
      </a-button>
    </a-space>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { isEmpty } from 'lodash';
import types from 'src/store/mutation-types';
import S3Service from 'src/services/s3';
import numberTypeTaskParamComponent from './numberTypeTaskParamComponent.vue';
import MultiselectTypeTaskParamComponent from './MultiselectTypeTaskParamComponent.vue';
import SubStepTimesJson from './SubStepTimesJson.vue';

export default {
  name: 'EditTaskJsonModal',
  setup: () => ({ types }),
  components: {
    MultiselectTypeTaskParamComponent,
    numberTypeTaskParamComponent,
    SubStepTimesJson,
  },
  inject: ['toast', 'deepClone'],
  data() {
    return {
      updatedTaskJson: {},
      stepsNumbersWithinEachProcess: [],
      stepsNumbersWithinEachProcessForStart: [],
      stepsNumbersWithinEachProcessForEnd: [],
      selected_required_steps_for_start: {},
      selected_required_steps_for_end: {},
      selectedAugmentations: [],
      augmentations: ['crop', 'color', 'blackout', 'affine'],
      // models: [
      //   'Model A',
      //   'Model C',
      //   'Model D',
      //   'Model AL',
      //   'Model CL',
      //   'Model O',
      //   'Model AD',
      // ],
      selectedModels: [],

      //default values for training and cycle calculation params
      // default_maximum_cycle_time_multiplier: 4.0,
      // default_minimum_cycle_time_multiplier: 0.4,
      default_min_percentage_of_steps_required_to_end_cycle: 0.75,
      default_threshold: 70,
      default_spatial_model_iterations: 10000,
      default_spatial_model_batch_size: 8,
      default_contextual_model_iterations: 30,
      default_contextual_model_batch_size: 4,
      default_model_choice: '',
      default_augmentations: ['blackout', 'crop'],

      telemetryParams: [],
    };
  },

  async created() {
    this.setStepsAccordingToProcesses();
    await this.refreshParameters();
    this.setupParamsConfig();
    this.getModelTypes();
  },

  computed: {
    ...mapGetters([
      'taskProcesses',
      'organization',
      'taskName',
      'selectedTask',
      'taskJson',
      'isUpdatingTaskParams',
      'subStepTimesJson',
      'stepsToIndexMapping',
      'modelTypes',
    ]),
  },

  methods: {
    ...mapActions(['updateTaskJson', 'showModal', 'getModelTypes']),

    setStepsAccordingToProcesses() {
      const dc = this.deepClone;
      let stepsInProcesses = this.taskProcesses.map((process) => {
        const allSubsteps = process.steps.reduce(
          (res, step) => [...res, ...step.substeps],
          []
        );
        return Object.keys(allSubsteps);
      });
      this.stepsNumbersWithinEachProcess = dc(stepsInProcesses);
      this.stepsNumbersWithinEachProcessForStart = dc(stepsInProcesses);
      this.stepsNumbersWithinEachProcessForEnd = dc(stepsInProcesses);
    },

    refreshParameters() {
      return new Promise(async (resolve, _) => {
        const { deepClone: dc } = this;
        const processesIndexToEmptListMap = this.getProcessesIndexToEmptyListMap();
        this.selected_required_steps_for_start = dc(
          processesIndexToEmptListMap
        );
        this.selected_required_steps_for_end = dc(processesIndexToEmptListMap);
        this.selectedAugmentations = [];
        this.selectedModels = [];
        this.setupUpdateTaskJson();
        resolve();
      });
    },

    setupParamsConfig() {
      this.telemetryParams = [
        {
          title: 'Required Steps for Cycle Start',
          param: 'cycle_calculation_params',
          taskParam: 'required_steps_for_start',
          updatedTaskJson: this.updatedTaskJson,
          processes: this.taskProcesses,
          options: this.stepsNumbersWithinEachProcessForStart,
          selectedOptions: this.selected_required_steps_for_start,
          isProcessBased: true,
          updateTaskParam: this.updateTaskParam,
        },
        {
          title: 'Required Steps for Cycle End',
          param: 'cycle_calculation_params',
          taskParam: 'required_steps_for_end',
          updatedTaskJson: this.updatedTaskJson,
          processes: this.taskProcesses,
          options: this.stepsNumbersWithinEachProcessForEnd,
          selectedOptions: this.selected_required_steps_for_end,
          isProcessBased: true,
          updateTaskParam: this.updateTaskParam,
        },
      ];
    },

    updateTaskParam(param, taskParam, val) {
      this.updatedTaskJson[param][taskParam] = val;
    },

    onChangeStepsRequired(selectedSteps, currentProcess, param) {
      var unselectedSteps = this.stepsNumbersWithinEachProcess[
        currentProcess
      ]?.filter((item) => !selectedSteps.includes(item));
      if (param === 'required_steps_for_start') {
        this.stepsNumbersWithinEachProcessForEnd[
          currentProcess
        ] = unselectedSteps;
      } else {
        this.stepsNumbersWithinEachProcessForStart[
          currentProcess
        ] = unselectedSteps;
      }
    },

    getRequiredStepsList(currentProcess, param) {
      return String(
        this.updatedTaskJson.cycle_calculation_params[param][currentProcess]
      )
        .split(',')
        .map(Number)
        .sort(function(a, b) {
          return a - b;
        });
    },

    getEmptyProcessesObject() {
      const emptyProcessList = Array(this.taskProcesses.length).fill(null);
      return Object.assign({}, emptyProcessList);
    },

    getProcessesIndexToEmptyListMap() {
      return this.taskProcesses.reduce((res, _, index) => {
        res[index] = [];
        return res;
      }, {});
    },

    getNewCycleCalculationParams() {
      const temp = {};
      temp['required_steps_for_end'] = this.getEmptyProcessesObject();
      temp['required_steps_for_start'] = this.getEmptyProcessesObject();
      temp[
        'min_percentage_of_steps_required_to_end_cycle'
      ] = this.default_min_percentage_of_steps_required_to_end_cycle;
      // temp[
      //   'maximum_cycle_time_multiplier'
      // ] = this.default_maximum_cycle_time_multiplier;
      // temp[
      //   'minimum_cycle_time_multiplier'
      // ] = this.default_minimum_cycle_time_multiplier;
      return temp;
    },

    getNewTrainingParams() {
      const temp = {};
      temp['augmentations'] = this.default_augmentations;
      temp['model_choice'] = this.default_model_choice;
      temp['threshold'] = this.default_threshold;
      temp['spatial_model_iterations'] = this.default_spatial_model_iterations;
      temp['spatial_model_batch_size'] = this.default_spatial_model_batch_size;
      temp[
        'contextual_model_iterations'
      ] = this.default_contextual_model_iterations;
      temp[
        'contextual_model_batch_size'
      ] = this.default_contextual_model_batch_size;
      return temp;
    },

    createNewUpdatedTaskJsonIfNull() {
      if (this.updatedTaskJson === null) {
        const temp = {};
        temp['taskID'] = this.selectedTask;
        temp['name'] = this.taskName;
        temp['dirname'] = this.taskName;
        temp['cycle_calculation_params'] = this.getNewCycleCalculationParams();
        temp['training_params'] = this.getNewTrainingParams();
        this.updatedTaskJson = { ...temp };
      }
    },

    getRequiredStepForEnd() {
      const { required_steps_for_end } = this.updatedTaskJson[
        'cycle_calculation_params'
      ];

      const isRequiredStepsToEndEmpty = isEmpty(required_steps_for_end);
      if (isRequiredStepsToEndEmpty)
        return this.getProcessesIndexToEmptyListMap();

      return required_steps_for_end;
    },

    getRequiredStepForStart() {
      const { required_steps_for_start } = this.updatedTaskJson[
        'cycle_calculation_params'
      ];

      const isRequiredStepsToStartEmpty = isEmpty(required_steps_for_start);

      if (isRequiredStepsToStartEmpty)
        return this.getProcessesIndexToEmptyListMap();

      return required_steps_for_start;
    },

    setupCycleCalculationParams() {
      const temp = { ...this.updatedTaskJson };
      const hasCycleParams = temp['cycle_calculation_params'];
      const isEmptyParamsObj = isEmpty(temp['cycle_calculation_params']);

      if (!hasCycleParams || isEmptyParamsObj) {
        temp['cycle_calculation_params'] = this.getNewCycleCalculationParams();
      } else {
        temp['cycle_calculation_params'][
          'required_steps_for_end'
        ] = this.getRequiredStepForEnd();

        temp['cycle_calculation_params'][
          'required_steps_for_start'
        ] = this.getRequiredStepForStart();
      }
      this.updatedTaskJson = { ...temp };
    },

    setupTrainingParams() {
      if (!this.updatedTaskJson.hasOwnProperty('training_params'))
        this.updatedTaskJson['training_params'] = this.getNewTrainingParams();
    },

    setupSelectedTaskParams() {
      this.selectedAugmentations = this.updatedTaskJson.training_params.augmentations;
      this.selectedModels = this.updatedTaskJson.training_params.model_choice;
      this.selected_required_steps_for_start = this.updatedTaskJson.cycle_calculation_params.required_steps_for_start;
      this.selected_required_steps_for_end = this.updatedTaskJson.cycle_calculation_params.required_steps_for_end;
    },

    setupUpdateTaskJson() {
      this.updatedTaskJson = this.deepClone(this.taskJson);
      this.createNewUpdatedTaskJsonIfNull();
      this.setupCycleCalculationParams();
      this.setupTrainingParams();
      this.setupSelectedTaskParams();
    },

    validateTaskParams(param, taskParam, minParam, maxParam) {
      if (
        this.updatedTaskJson[param][taskParam] < minParam ||
        this.updatedTaskJson[param][taskParam] > maxParam
      ) {
        this.toast.error(
          `the value of ${taskParam}  can not be lower than ${minParam}  or greater than ${maxParam}`
        );
        return false;
      }
      return true;
    },

    validateParams() {
      if (!this.validateTaskParams('training_params', 'threshold', 1, 100)) {
        return false;
      }
      if (
        !this.validateTaskParams(
          'training_params',
          'spatial_model_iterations',
          5000,
          20000
        )
      ) {
        return false;
      }
      if (
        !this.validateTaskParams(
          'training_params',
          'spatial_model_batch_size',
          4,
          32
        )
      ) {
        return false;
      }
      if (
        !this.validateTaskParams(
          'training_params',
          'contextual_model_iterations',
          20,
          100
        )
      ) {
        return false;
      }
      if (
        !this.validateTaskParams(
          'training_params',
          'contextual_model_batch_size',
          4,
          32
        )
      ) {
        return false;
      }
      return true;
    },

    async updateTaskJsonParams() {
      if (!this.validateTaskPercentageParameters()) return;
      // if (!this.validateTaskMultiplierParameters()) return;
      if (!this.validateParams()) return;
      this.processTaskParameters();
      const payload = {
        taskParams: this.getTaskParams(),
        taskJson: this.updatedTaskJson,
      };
      console.log({ payload });
      // return;
      await this.addSubStepTimesJson();
      this.updateTaskJson(payload);
      // var taskParams = this.getTaskParams();

      // const res = await httpClient.patch(
      //   'organization/task/',
      //   this.selectedTask + '/',
      //   payload,
      //   true,
      //   false,
      //   false
      // );
      // if (res === 'error') {
      //   this.toast.error('Error occured while updating task json! this toast');
      //   return;
      // }
      // this.$emit('updateTaskJsonEmit', this.updatedTaskJson);
      // this.toast.success('Task Json updated successfully!');
    },

    validateTaskPercentageParameters() {
      if (!this.isTaskPercentageParametersValid()) {
        this.toast.error(
          'Please enter correct percentage value! (Valid range is between 0.00% and 1.00%)'
        );
        return false;
      }
      return true;
    },

    isTaskPercentageParametersValid() {
      return this.isValidPercentage(
        this.updatedTaskJson.cycle_calculation_params
          .min_percentage_of_steps_required_to_end_cycle
      );
    },

    isValidPercentage(val) {
      if (!val) return true;
      const percentageAsFloat = parseFloat(val);
      return percentageAsFloat > 0 && percentageAsFloat <= 1;
    },

    validateTaskMultiplierParameters() {
      if (!this.isTaskMultiplierParametersValid()) {
        this.toast.error(
          'Please enter correct multiplier value! (Valid range is between 0 and 100)'
        );
        return false;
      }
      return true;
    },

    isTaskMultiplierParametersValid() {
      return (
        this.isValidMutiplier(
          this.updatedTaskJson.cycle_calculation_params
            .minimum_cycle_time_multiplier
        ) &&
        this.isValidMutiplier(
          this.updatedTaskJson.cycle_calculation_params
            .maximum_cycle_time_multiplier
        )
      );
    },

    isValidMutiplier(val) {
      if (!val) return true;
      const percentageAsFloat = parseFloat(val);
      return percentageAsFloat > 0 && percentageAsFloat < 100;
    },

    processTaskParameters() {
      this.updatedTaskJson.cycle_calculation_params.maximum_cycle_time_multiplier = this.getCycleTimeMultiplier(
        'maximum_cycle_time_multiplier'
      );
      this.updatedTaskJson.cycle_calculation_params.minimum_cycle_time_multiplier = this.getCycleTimeMultiplier(
        'minimum_cycle_time_multiplier'
      );
      this.convertNumberTaskParamsToNullIfNan();
    },

    convertNumberTaskParamsToNullIfNan() {
      this.convertToNullIfNan(
        this.updatedTaskJson.cycle_calculation_params.maximum_cycle_time
      );
      this.convertToNullIfNan(
        this.updatedTaskJson.cycle_calculation_params.minimum_cycle_time
      );
      this.convertToNullIfNan(
        this.updatedTaskJson.cycle_calculation_params
          .maximum_cycle_time_multiplier
      );
      this.convertToNullIfNan(
        this.updatedTaskJson.cycle_calculation_params
          .minimum_cycle_time_multiplier
      );
      this.convertToNullIfNan(
        this.updatedTaskJson.cycle_calculation_params
          .min_percentage_of_steps_required_to_end_cycle
      );
      this.convertToNullIfNan(this.updatedTaskJson.training_params.threshold);
      this.convertToNullIfNan(
        this.updatedTaskJson.training_params.spatial_model_iterations
      );
      this.convertToNullIfNan(
        this.updatedTaskJson.training_params.spatial_model_batch_size
      );
      this.convertToNullIfNan(
        this.updatedTaskJson.training_params.contextual_model_batch_size
      );
      this.convertToNullIfNan(
        this.updatedTaskJson.training_params.contextual_model_iterations
      );
    },

    convertToNullIfNan(val) {
      return isNaN(val) ? null : val;
    },

    getTaskParams() {
      const cycleParamsObject = this.getCycleParamsObject();
      const trainingParamsObject = this.getTraininParamsObject();

      return {
        ...cycleParamsObject,
        ...trainingParamsObject,
      };
    },

    getCycleParamsObject() {
      const { convertToNullIfNan, updatedTaskJson } = this;
      const { cycle_calculation_params } = updatedTaskJson;
      const {
        maximum_cycle_time,
        minimum_cycle_time,
        maximum_cycle_time_multiplier,
        minimum_cycle_time_multiplier,
        min_percentage_of_steps_required_to_end_cycle,
        required_steps_for_start,
        required_steps_for_end,
      } = cycle_calculation_params;

      return {
        maximum_cycle_time: convertToNullIfNan(maximum_cycle_time),
        minimum_cycle_time: convertToNullIfNan(minimum_cycle_time),
        maximum_cycle_time_multiplier: convertToNullIfNan(
          maximum_cycle_time_multiplier
        ),
        minimum_cycle_time_multiplier: convertToNullIfNan(
          minimum_cycle_time_multiplier
        ),
        min_percentage_of_steps_required_to_end_cycle: convertToNullIfNan(
          min_percentage_of_steps_required_to_end_cycle
        ),
        required_steps_for_start: JSON.stringify(required_steps_for_start),
        required_steps_for_end: JSON.stringify(required_steps_for_end),
      };
    },

    getTraininParamsObject() {
      const { convertToNullIfNan, updatedTaskJson } = this;
      const { training_params } = updatedTaskJson;

      const {
        augmentations,
        model_choice,
        threshold,
        spatial_model_iterations,
        spatial_model_batch_size,
        contextual_model_iterations,
        contextual_model_batch_size,
      } = training_params;

      return {
        augmentations: JSON.stringify(augmentations),
        model_choice: model_choice,
        threshold: convertToNullIfNan(threshold),
        spatial_model_iterations: convertToNullIfNan(spatial_model_iterations),
        spatial_model_batch_size: convertToNullIfNan(spatial_model_batch_size),
        contextual_model_iterations: convertToNullIfNan(
          contextual_model_iterations
        ),
        contextual_model_batch_size: convertToNullIfNan(
          contextual_model_batch_size
        ),
      };
    },
    sum(obj) {
      return Object.keys(obj).reduce(
        (sum, key) => sum + parseFloat(obj[key] || 0),
        0
      );
    },
    getCycleTimeMultiplier(taskParam) {
      return (
        this.updatedTaskJson['cycle_calculation_params'][taskParam] /
        this.sum(this.subStepTimesJson)
      );
    },
    async addSubStepTimesJson() {
      let json = {};
      if (!Object.keys(this.subStepTimesJson).length) return;
      const steps = Object.entries(this.stepsToIndexMapping);
      steps.forEach(([step, index]) => {
        json[index] = this.subStepTimesJson[step]
          ? Number(this.subStepTimesJson[step])
          : 0;
      });
      // add backgorund time on last index
      if (!this.subStepTimesJson['background']) {
        this.toast.info('please add background time in substep Times');
        return;
      }
      json[steps.length] = Number(this.subStepTimesJson['background']);
      json = JSON.stringify(json);
      const payload = this.getSubStepTimesJsonPayload(json);
      const [error, _] = await S3Service.uploadFile(payload, false);
      if (error) return;
    },

    getSubStepTimesJsonPayload(json) {
      var formData = new FormData();
      const bucket = `${this.organization}-training`;
      const filePath = `${this.taskName}/subStepTimes.json`;
      var blob = new Blob([json], { type: 'text/json;charset=utf-8' });
      formData.append('file', blob, 'subStepTimes.json');
      formData.append('file_path', filePath);
      formData.append('bucket', bucket);
      return formData;
    },
  },
};
</script>
