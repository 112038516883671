<template>
  <a-col span="24">
    <a-card :title="title" size="small" hoverable>
      <a-row :gutter="[0, 4]">
        <template v-if="isProcessBased === true">
          <a-col
            v-for="(value, index) in getUpdatedTaskJson"
            :key="index"
            span="24"
            class="d-flex align-items-center"
            :id="id + '-col'"
          >
            <a-typography class="w-25">
              Process: <strong>{{ getProcessName(index) }}</strong>
            </a-typography>

            <a-select
              :value="multiProcessSelectedOptions[index]"
              class="w-75"
              mode="multiple"
              :options="
                options[index].map((e) => ({ label: e, value: Number(e) }))
              "
              @change="(value) => handleChange(value, index)"
              placeholder="Select"
              :id="id + '-select'"
            />
          </a-col>
        </template>
        <template v-else>
          <a-col
            span="24"
            v-if="options.length > 0 && !isSingleSelect"
            :id="id + '-col'"
          >
            <a-select
              class="w-100"
              v-model:value="singleProcessSelectedOptions"
              mode="multiple"
              :options="options.map((e) => ({ label: e, value: e }))"
              placeholder="Select"
              :id="id + '-select'"
            />
          </a-col>
          <a-col
            span="24"
            v-else-if="options.length > 0 && isSingleSelect"
            :id="id + '-col'"
          >
            <a-select
              class="w-100"
              v-model:value="singleProcessSelectedOptions"
              :options="options.map((e) => ({ label: e, value: e }))"
              placeholder="Select"
              :id="id + '-select'"
            />
          </a-col>
        </template>
      </a-row>
    </a-card>
  </a-col>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'multiselect-type-task-param-component',
  props: [
    'title',
    'param',
    'taskParam',
    'updatedTaskJson',
    'isProcessBased',
    'options',
    'selectedOptions',
    'isSingleSelect',
    'id',
  ],
  emits: ['updateTaskParam'],
  data() {
    return {
      multiProcessSelectedOptions: {},
      singleProcessSelectedOptions: [],
    };
  },

  mounted() {
    if (this.selectedOptions) this.updateSelectedOptions(this.selectedOptions);
  },

  computed: {
    ...mapGetters(['taskProcesses']),

    getUpdatedTaskJson() {
      if (!this.updatedTaskJson[this.param]) return {};
      return this.updatedTaskJson[this.param][this.taskParam];
    },
  },

  watch: {
    multiProcessSelectedOptions(val) {
      this.updateTaskParam(val);
    },

    singleProcessSelectedOptions(val) {
      this.updateTaskParam(val);
    },

    selectedOptions(val) {
      if (val) this.updateSelectedOptions(val);
    },
  },

  methods: {
    updateSelectedOptions(val) {
      if (this.isProcessBased) this.multiProcessSelectedOptions = val;
      else this.singleProcessSelectedOptions = val;
    },

    updateTaskParam(val) {
      this.$emit('updateTaskParam', this.param, this.taskParam, val);
    },

    getProcessName(index) {
      return this.taskProcesses[index]?.name;
    },

    handleChange(value, index) {
      const temp = { ...this.multiProcessSelectedOptions };
      temp[index] = value;
      this.multiProcessSelectedOptions = temp;
    },
  },
};
</script>
