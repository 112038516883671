export default {
  controls: true,
  bigPlayButton: false,
  width: 520,
  height: 280,
  fluid: false,
  controlBar: {
    volumePanel: false,
  },
  plugins: {
    record: {
      pip: false,
      audio: false,
      maxLength: 60 * 23 * 60,
      debug: true,
      convertEngine: 'ts-ebml',
      videoMimeType: 'video/webm;codecs=h264',
      videoFrameRate: 30,
      video: {
        minWidth: 640,
        minHeight: 480,
      },
      frameWidth: 640,
      frameHeight: 480,
    },
  },
};
